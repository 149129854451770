<template>
 <div>
  <svg height="183" viewBox="0 0 183 183" width="183" xmlns="http://www.w3.org/2000/svg">
   <g id="icon_ZUYD" transform="translate(-2648 -2882)">
    <g data-name="Group 499" id="Group_499" transform="translate(-674 241)">
     <g data-name="Group 496" id="Group_496" transform="translate(880 23)">
      <g data-name="Group 469" id="Group_469" transform="translate(-228 263)">
       <rect :fill="background" data-name="Rectangle 186" height="183" id="Rectangle_186" transform="translate(2670 2355)" width="183"/>
      </g>
     </g>
    </g>
    <g data-name="svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJMYXllcl8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjE0OHB4IiBoZWlnaHQ9IjE2MHB4IiB2aWV3Qm94PSIwIDAgM" id="svg_xml_base64_PHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJMYXllcl8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjE0OHB4IiBoZWlnaHQ9IjE2MHB4IiB2aWV3Qm94PSIwIDAgM" transform="translate(2696.313 2928.543)">
     <path d="M100.526,99.111c2.925,0,6.608-.062,9.207,3.408,1.738,2.385,2.008,5.039,2.008,7.694,0,11.005-7.042,11.27-12.142,11.382H96.674V99.106Zm-13.5-8.081v38.259H102.1c4-.057,14.678-.27,18.2-12.08a25.885,25.885,0,0,0,.972-7.378c0-5.36-1.518-10.724-5.471-14.3-4.876-4.336-11.923-4.443-16.585-4.5Z" fill="#ed1b34" id="_x38_2d6a" transform="translate(-37.726 -39.696)"/>
     <path d="M.82,91.026h11l8.239,13.114a18.311,18.311,0,0,1,1.575-2.868l6.552-10.247h10.9L24.609,111.187v18.1H15.183V111.456L.82,91.02" fill="#ed1b34" id="_x38_2d6b" transform="translate(0 -39.692)"/>
     <path d="M119.708.32V21.668c-.057,3.689-.113,7.373-2.222,10.843-4.122,6.608-12.631,6.934-14.256,6.934a20.334,20.334,0,0,1-8.886-2.058c-7.368-3.8-7.368-10.517-7.311-15.719V.32h9.639V24.165a10.276,10.276,0,0,0,.709,4.769c1.187,2,3.79,2.6,6.175,2.6,6.5,0,6.5-4.443,6.563-7.423V.32h9.589" fill="#ed1b34" id="_x38_2d6c" transform="translate(-37.724)"/>
     <path d="M7.668.32H38.555V7.8L17.7,31.043H38.453v7.536H5.88V31.425L27.071,7.794H7.668V.32" fill="#ed1b34" id="_x38_2d6d" transform="translate(-2.214)"/>
    </g>
   </g>
  </svg>
 </div>
</template>
<script>
export default {
  props: ["color1", "color2", "background"],
};
</script>